<template>
  <div class="sidebar-content" :style="`height: ${getSidebarHeight}vh`">
    <div>
      <div class="sidebar-header">
        <div class="sidebar-header-content">
          <span type="button" @click="closeSidebar">
            <chevrons-left-icon size="18" />
          </span>
          <span class="badge bg-ra-gray"
                style="cursor:pointer;background-color: var(--app-primary) !important;"
          @click="clearAllFilters" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Clear all filters">Clear</span>
        </div>
      </div>
      <transition mode="out-in" name="slide-fade">
        <p v-if="isLoading" class="pt-5 px-3">Loading...</p>
        <div v-else class="sidebar-container">

          <FilterItem
              filter-name="Last Updated"
              key-value="last_updated"
              tooltip="sidebar_last_updated"
          />

          <FilterItem
              filter-name="Comments Due"
              key-value="comments_due"
              tooltip="sidebar_comments_due"
          />

          <FilterItem
              filter-name="Agency"
              key-value="agencies"
              tooltip="sidebar_agency"
          />

          <!--<FilterItem
              filter-name="Status"
              key-value="status"
          />-->
          <FilterItem
              filter-name="Agency Classification"
              key-value="classification"
              tooltip="sidebar_agency_classification"
          />

          <FilterItem
              filter-name="Country"
              key-value="countries"
              tooltip="sidebar_country"
          />

          <FilterItem
              filter-name="Sector"
              key-value="sector_type"
              tooltip="sidebar_sector"
          />
          <FilterItem
              filter-name="Alert Classification"
              key-value="higher_order_alert_classification"
              tooltip="sidebar_alert_classification"
          />

          <FilterItem
              filter-name="Alert Subclassification"
              key-value="subtype_classification"
              tooltip="sidebar_alert_sub_classification"
          />
          <FilterItem
              filter-name="Alert Type"
              key-value="alert_type"
              tooltip="sidebar_alert_type"

          />
          <FilterItem
              filter-name="Tags"
              key-value="tags"
              tooltip="sidebar_tags"
          />

          <!-- <FilterItem
              filter-name="Filing Type"
              key-value="filing_type"
          />-->
          <FilterItem
              filter-name="In Federal Register"
              key-value="in_federal_register"
              style="margin-bottom: 200px;"
              tooltip="sidebar_in_federal_register"
          />

          
          
          

          

        </div>
      </transition>

    </div>
  </div>
</template>

<script>
import FilterItem from "@/components/articles/articles-filters-sidebar/FilterItem";
import { ChevronsLeftIcon } from 'vue-feather-icons';
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ArticlesFilterSidebar",

  components: {
    FilterItem,
    ChevronsLeftIcon
  },

  computed: {
    ...mapGetters('articles', ['getFilterOptions']),
    ...mapGetters('filters', ['getCurrentFilters', 'isLoading']),
    ...mapGetters('application', ['getWindowHeight']),

    getSidebarHeight() {
      if (this.getWindowHeight > 1000) return 88;
      if (this.getWindowHeight > 840) return 85;
      if (this.getWindowHeight > 780) return 83;
      if (this.getWindowHeight > 650) return 82;
      if (this.getWindowHeight > 500) return 80;
      return 75;
    }

  },

  methods: {
    ...mapActions('filters', ['getFilters', 'clearFilters', 'setLoadingStatusFiltersSidebar', 'openFiltersSidebar']),

    // Emits to the parent component to close the sidebar
    updateSidebar() {
      this.$store.dispatch('sidebar/openOrCloseSidebar');
    },

    closeSidebar() {
      this.openFiltersSidebar(false);
    },

    clearAllFilters() {
      this.clearFilters();
    },

  }

}
</script>

<style lang="scss" scoped>
/*.sidebar-background {
  box-sizing: border-box;
  background: rgba(0,0,0,.2);
  margin-right: 20px;
}*/

.sidebar-content {
  transition: 0.25s;
  background: #ffffff;
  width: 100%;
  height: 82vh;
  overflow-y: auto;
  max-width: 260px;
  margin-right: 20px;
  border-radius: .5rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, .1);
  -webkit-transition: all .3s ease-in-out;
  display: block;
  position: fixed;
  margin-bottom: 15px;
  z-index: 1;
}

.sidebar-container {
  padding-top: 60px;
  padding-left: 15px;
  padding-right: 15px;
}

.sidebar-header {
  box-sizing: border-box;
  display: block;
}

.sidebar-header-content {
  position: fixed;
  width: 240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 0.6rem 0.5rem;
  border-radius: .5rem;
  z-index: 2;
}

.bg-primary {
  background: #0056b3 !important;
}
</style>
