<template>
  <div class="filter-container">
    <p class="filter-title">{{ filterName }} <span v-if="getTooltips.length>0" >
      <span  data-toggle="tooltip" data-placement="top" :title= getTooltips[0].description><info-icon size="20" /></span>
    </span></p>

    <span v-if="getFilters.length <= 0 || !getFilters">Nothing found...</span>
    <div v-if="getFilters.length > 3">

      <template v-if="!showAllResults">
        <ul>
          <li v-for="(item, index) in getFilters.slice(0, 3)" :key="index"
              @click="() => addItemToStore(item)">
            <div class="filter-item d-flex justify-content-between align-items-center w-100"
                 v-if="item.label.trim() !== ''"
                 :class="item.selected ? 'selected' : 'not-selected'">
              <span>{{ item.label }}</span>
              <span class="badge bg-ra-gray ms-1" style="background-color: var(--app-primary) !important;">{{ item.count }}</span>
            </div>
          </li>
        </ul>
        <span class="expand-button" @click="showAllResults = true">
          <chevron-down-icon size="15"/>
        </span>
      </template>

      <template v-else>
        <ul>
          <li v-for="(item, index) in getFilters" :key="index"
              @click="() => addItemToStore(item)">
            <div class="filter-item d-flex justify-content-between align-items-center w-100"
                 v-if="item.label.trim() !== ''"
                 :class="item.selected ? 'selected' : 'not-selected'">
              <span>{{ item.label }}</span>
              <span class="badge bg-ra-gray ms-1" style="background-color: var(--app-primary) !important;">{{ item.count }}</span>
              
            </div>
          </li>
        </ul>
        <span class="expand-button" @click="showAllResults = false">
          <chevron-up-icon size="15"/>
        </span>
      </template>

    </div>

    <ul v-else>
      <li v-for="(item, index) in getFilters" :key="index"
          @click="() => addItemToStore(item)">
        <div class="filter-item d-flex justify-content-between align-items-center w-100"
             v-if="item.label.trim() !== ''"
             :class="item.selected ? 'selected' : 'not-selected'">
          <span>{{ item.label }}</span>
          <span class="badge bg-ra-gray ms-1" style="background-color: var(--app-primary) !important;">{{ item.count }}</span>

          
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { ChevronDownIcon, ChevronUpIcon, InfoIcon } from 'vue-feather-icons';
export default {
  name: "FilterItem",
  components: {
    ChevronUpIcon,
    ChevronDownIcon,
    InfoIcon
  },
  props: {
    filterName: String,
    keyValue: String,
    tooltip: String
  },

  data() {
    return {
      showAllResults: false
    }
  },

  computed: {
    getFilters: function () {
      // Finds the item keys and returns if found
      const allFilters = this.$store.getters["filters/getCurrentFilters"];
      if (Object.prototype.hasOwnProperty.call(allFilters, this.keyValue)) {
        return allFilters[this.keyValue];
      } else {
        return false;
      }
    },
    getTooltips: function () {
      let tooltips = JSON.parse(localStorage.getItem('toolTips'))
      let pro_tooltip = this.tooltip
      return tooltips.filter((v, k) => {
        return v.name == pro_tooltip && v.description != ''
      })
    }
  },

  methods: {

    addItemToStore(item) {
      const itemData = {key: this.keyValue, label: item.label, selected: !item.selected};
      this.$store.dispatch('filters/selectItem', itemData);

      const filterItemCount = parseInt(item.count.toString().replace(',', ''));
      this.$tracking.trackEvent('selectedFilterOption', {
        filterItemLabel: item.label,
        selected: !item.selected,
        filterItemCount: filterItemCount
      });
    },

  }

}
</script>

<style lang="scss" scoped>
.filter-container {
  margin-bottom: 12px;

  .filter-title {
    font-size: 1.2rem;
  }

  ul {
    padding: 0 3px;
    margin-left: 0;

    li {
      list-style: none;
    }

    .filter-item {
      margin-top: 10px;
      cursor: pointer;
      color: #666666;

      padding: 6px 6px;
      border: 1px solid #eee;
      border-radius: 6px;
      box-shadow: 0 3px 3px 0 #ddd;
      font-size: 0.75rem;
      transition: all .2s;
      display: flex;
      justify-content: space-between;

      &:hover {
        color: #000000;
        box-shadow: 0 3px 3px 0 #cccccc;
      }
    }

    .selected {
      background-color: #eeeeee !important;
    }

    .not-selected {
      background-color: white !important;
    }

  }
}

.expand-button {
  margin: 10px 3px;
  cursor: pointer;
  color: #666666;
  list-style: none;
  padding: 6px 6px;
  border: 1px solid #eee;
  border-radius: 6px;
  box-shadow: 0 3px 3px 0 #ddd;
  font-size: 0.75rem;
  transition: all .2s;
  display: flex;
  justify-content: center;

  &:hover {
    color: #000000;
    box-shadow: 0 3px 3px 0 #cccccc;
  }
}
</style>
